import * as React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"

const ProjectTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`


    return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <article
        itemScope
        itemType="http://schema.org/Article"
      >

      <section className="title-project">
      <header>
        <h1 itemProp="headline">{post.frontmatter.title}</h1>
      </header>
      </section>
  

      <section className="div-project"
        dangerouslySetInnerHTML={{ __html: post.html }}
        itemProp="articleBody"/>


      <footer className="div-project">
        <p>Filipe Correia</p>
      </footer>
      
    </article>

  </Layout>
 )

}




export default ProjectTemplate

export const pageQuery = graphql`
  query ProjectBySlug(
    $id: String!
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        category
      }
      tableOfContents
    }
  }
`




/*


line 60 - to implement the signature side and scroller:

<div className="signature-fmcorreia-blog">
    <p>FMCorreia</p>
    <span className="progress-blog-post">

    </span>
</div>
*/